<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">岗位职务</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>岗位职务</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">岗位代码:</div>
          <el-input
            v-model="code"
            placeholder="请输入岗位代码"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">岗位编码:</div>
          <el-input
            v-model="number"
            placeholder="请输入岗位编码"
            clearable
          ></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">岗位名称:</div>
          <el-input
            v-model="keywords"
            placeholder="请输入岗位名称"
            clearable
          ></el-input>
        </div>
        <button class="btn-pre" @click="getPositions(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
         <button class="btn-pre" @click="uploadAllInfo">
          <i class="fa fa-upload"></i>导入
        </button>
         <button class="btn-pre" @click="downloadAll">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
           :empty-text="loading?'加载中...':'暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="岗位id" width="80" />
          <el-table-column prop="PositionsCHS" label="中文名称" width="80" />
          <el-table-column prop="PositionsEN" label="英文名称" width="150" />
          <el-table-column prop="PositionsAR" label="阿拉伯文" width="100" />
          <el-table-column prop="PositionCode" label="代码" width="100" />
          <el-table-column prop="SpecialityName" label="专业分类" width="100" />
          <el-table-column
            prop="SaudiSpecialityName"
            label="沙化分类"
            width="150"
          />
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
      
      
                <i
                  class="fa fa-edit"
                  v-if="powers.update"
                  @click="edit(scope.row)"
                ></i>
                <i
                  class="fa fa-trash"
                  v-if="powers.delete"
                  @click="deletePositions(scope.row)"
                ></i>
                
              
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getPositions(1)"
          @current-change="getPositions"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogType === 1 ? '新建岗位' : '编辑岗位'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">中文名称:</div>
            <el-input
              v-model="parameter.NameCHS"
              placeholder="中文名称"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">英文名称:</div>
            <el-input
              v-model="parameter.NameEN"
              placeholder="英文名称"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">阿拉伯文名称:</div>
            <el-input
              v-model="parameter.NameAR"
              placeholder="阿拉伯文名称"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">编码:</div>
            <el-input v-model="parameter.Number" placeholder="编码"></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">专业分类名称:</div>
            <!-- <el-input
              v-model="parameter.SpecialityName"
              placeholder="专业分类名称"
            ></el-input> -->
            <el-select
              v-model="parameter.SpecialityId"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in SpecialityNameList"
                :key="item.ID"
                :label="item.NameCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>

          <div class="from-box">
            <div class="name">沙化专业分类名称:</div>
            <!-- <el-input
              v-model="parameter.SaudiSpecialityName"
              placeholder="沙化专业分类名称"
            ></el-input> -->

                      <el-select
              v-model="parameter.SaudiSpecialityId"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in SaudiSpecialityNameList"
                :key="item.ID"
                :label="item.NameAra"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="line-box">
          <div class="single">
            <div class="name">代码:</div>
            <el-input v-model="parameter.Code" placeholder="代码"></el-input>
          </div>
        </div>

         <div class="line-box">
          <div class="single">
                   
            <div class="name">上传说明书:</div>
           <el-upload
          action="#"
          :auto-upload="true"
          :http-request="uploadAttachment3"
           ref="upload"
           class="upload-box2"
        >
              <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="upload-tbox" v-if="parameter.InstructionBookUrl" > <span @click="getDown(parameter.InstructionBookUrl)">点击下载:{{parameter.InstructionBookUrl | instruct}}</span> <i class="el-icon-close" @click="remove"></i></div>
            </el-upload>
          </div>
          
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="addUpPositions">保存</el-button>
      </div>
    </el-dialog>
                   <el-dialog
      title="导入岗位职务"
      :visible.sync="dialogVisible3"
      width="600px"
      :before-close="handleClose3"
      top="50px"
      class="dialog"
    >

    <div class="text-box">
      <div class="text-title">模板文件</div>
      <div class="text-name" @click="getDownloadUrl">岗位职务批量导入模板</div>
    </div>
      <div class="upload-box">
        <div class="left">
          <div v-if="name2" class="name">
             <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
             {{name2}}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment2"
          ref="uploadRef"
        >
          <button  class="btn-up"><svg-icon class="icon" icon-class="file"></svg-icon></svg>选择 ...</button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose3">关闭</el-button>
        <el-button class="pop-save pop-right" @click="addAttachment2">导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import {
  getPositions,
  addPositions,
  updatePositions,
  deletePositions,
  allPositionCategory,
  allPositionLevel,
  getPositionTemplate,
  getPositionImport,
  getPositionExport,
  uploadAttachment,
  upload,
} from "@/api/user";
export default {
  filters: {
    instruct(data) {
      let end = data.substring(data.indexOf("."), data.length);
      let name = data.substring(data.indexOf("/") + 1, data.indexOf("_"));
      return name + end;
    },
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑
      options1: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 2,
          label: "否",
        },
      ],
      senior: false,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      page: 1,
      size: 10,
      total: null,
      code: null,
      number: null,
      keywords: null,
      SpecialityNameList: [],
      SaudiSpecialityNameList: [],
      parameter: {
        Id: 0,
        NameCHS: "",
        NameEN: "",
        NameAR: "",
        Code: "",
        Number: "",
        SpecialityId: null,
        SpecialityName: "",
        SaudiSpecialityId: null,
        SaudiSpecialityName: "",
        InstructionBookUrl: "",
      },
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      dialogVisible3: false,
      downloadUrl: null,
      uploadUrl: null,
      name2: null,
      loading: true,
    };
  },

  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },

    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        Id: 0,
        NameCHS: "",
        NameEN: "",
        NameAR: "",
        Code: "",
        Number: "",
        SpecialityId: null,
        SpecialityName: "",
        SaudiSpecialityId: null,
        SaudiSpecialityName: "",
        InstructionBookUrl: "",
      };
    },
    handleClose3() {
      this.dialogVisible3 = false;
      this.name2 = null;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.roleName = "";
      this.enable = false;
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;
      const multipleSelectionItem = row;

      this.parameter = {
        Id: multipleSelectionItem.ID,
        NameCHS: multipleSelectionItem.PositionsCHS,
        NameEN: multipleSelectionItem.PositionsEN,
        NameAR: multipleSelectionItem.PositionsAR,
        Code: multipleSelectionItem.PositionCode,
        Number: multipleSelectionItem.PositionNumber,
        SpecialityId: multipleSelectionItem.SpecialityId,
        SpecialityName: multipleSelectionItem.SpecialityName,
        SaudiSpecialityId: multipleSelectionItem.SaudiSpecialityId,
        SaudiSpecialityName: multipleSelectionItem.SaudiSpecialityName,
        InstructionBookUrl: multipleSelectionItem.InstructionBookUrl,
      };
    },

    //获取角色列表
    getPositions(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        code: this.code,
        number: this.number,
        keywords: this.keywords,
      };
      getPositions(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          this.loading = false;
        }
      });
    },
    //添加编辑岗位
    addUpPositions(type) {
      if (this.dialogType === 1) {
        //新增
        addPositions(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getPositions();
            this.handleClose();
          }
        });
      } else {
        updatePositions(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getPositions();
            this.handleClose();
          }
        });
      }
    },
    //删除岗位
    deletePositions(row) {
      let data = { id: "" };
      data.id = row.ID;

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePositions(data).then((res) => {
          //
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getPositions();
          }
        });
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    getDown(url) {
      location.href = process.env.VUE_APP_SERVER + "upload/" + url;
    },
    //分类
    allPositionCategory() {
      allPositionCategory().then((res) => {
        if (res.status === 200) {
          this.SpecialityNameList = res.response;
        }
      });
    },
    //沙化
    allPositionLevel() {
      allPositionLevel().then((res) => {
        if (res.status === 200) {
          this.SaudiSpecialityNameList = res.response;
        }
      });
    },

    uploadAllInfo() {
      getPositionTemplate().then((res) => {
        if (res.status === 200) {
          this.dialogVisible3 = true;
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          this.downloadUrl = "https://hrms.baishulai.com/" + url;
        }
      });
    },

    getDownloadUrl() {
      location.href = this.downloadUrl;
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
        }
      });
    },
    uploadAttachment3(file) {
      console.log(
        "this.$refs.upload.uploadFiles",
        this.$refs.upload.uploadFiles
      );
      this.$refs.upload.uploadFiles = [];
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "pnf";
      const suffix2 = img === "docx";
      const suffix3 = img === "png";
      const suffix4 = img === "jpg";
      const suffix5 = img === "gif";
      if (!suffix1 && !suffix2 && !suffix3 && !suffix4 && !suffix5) {
        this.$message.error("只能上传后缀为pnf、docx、png、jpg、gif的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      upload(formData).then((res) => {
        if (res.status === 200) {
          this.parameter.InstructionBookUrl = res.response;
        }
      });
    },
    remove() {
      console.log("溢出");
      this.parameter.InstructionBookUrl = "";
    },
    addAttachment2() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      getPositionImport(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose3();
        }
      });
    },
    downloadAll() {
      getPositionExport().then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
  },

  created() {
    this.getPositions();
    this.getButtonPower();

    this.allPositionCategory();
    this.allPositionLevel();
  },
};
</script>

<style lang="scss" scoped>
.upload-box2 {
  display: flex;
  align-items: center;
  margin-top: 0;
}
.upload-tbox {
  // margin-top: 10px;
  margin-left: 10px;
  i {
    margin-left: 5px;
    cursor: pointer;
  }
  span {
    text-decoration: underline;
    color: #337ab7;
    cursor: pointer;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 340px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 80px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}

.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
</style>
